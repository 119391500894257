import consumer_journey_orderFreeApprove from '@/lib/data-service/haolv-default/consumer_journey_orderFreeApprove'
export default {
    data() {
        return {
            orderNo: '',
            evectionType: 2,
            orderFreeApprove: false,
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo || ''
        this.getOrderFreeApprove();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getOrderFreeApprove() {
            consumer_journey_orderFreeApprove({
                businessType: 1,
                orderNo: this.orderNo
            }).then(res => {
                this.orderFreeApprove = res.datas.orderFreeApprove
            })
        },
        goDetail() {
            if (this.orderNo) {
                this.$router.replace({
                    name: 'admin-my-order-flight-detail',
                    query: {
                        orderNo: this.orderNo,
                        evectionType: this.evectionType,
                    }
                })
            }
        },
    }
}
